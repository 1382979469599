@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_italic-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_italic-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_semi_bold-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_semi_bold-webfont.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_bold-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_bold-webfont.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_bold_italic-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_bold_italic-webfont.woff2) format("woff2");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_italic-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_italic-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_semi_bold-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_semi_bold-webfont.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_bold-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_bold-webfont.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_bold_italic-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_bold_italic-webfont.woff2) format("woff2");
  font-weight: 600;
  font-style: italic;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "esphimere", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  line-height: 1.4;
  min-height: 100vh;
  height: 100%;
  color: #4d4d4d;
}

p {
  font-size: 1.265rem;
}
@media (max-width: 574px) {
  p {
    font-size: 1.115rem;
  }
}
@media (min-width: 576px) {
  p {
    font-size: 1.12rem;
  }
}
@media (min-width: 992px) {
  p {
    font-size: 1.125rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

