$internalSpace: 3.25rem;

@mixin breakpoint($name) {
    @if $name =="vrsmall" {
        @media (max-width: 574px) {
            @content;
        }
    }

    @else if $name =="small" {
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $name =="mdsmall" {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $name =="medium" {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $name =="mdlarge" {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @else if $name =="large" {
        @media (min-width: 1400px) {
            @content;
        }
    }
}

.container {
    @include breakpoint(vrsmall) {
        max-width: 100%;
    }

    @include breakpoint(small) {
        max-width: 540px;
    }

    @include breakpoint(mdsmall) {
        max-width: 720px;
    }

    @include breakpoint(medium) {
        max-width: 960px;
    }

    @include breakpoint(mdlarge) {
        max-width: 1140px;
    }

    @include breakpoint(large) {
        max-width: 1320px;
    }
}

.grid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(12, 1fr);
}

.bgColorElement {
    background-color: #485db1;
}

.erroForm {
    display: block;
    color: #F9A825;
    font-size: 0.890rem;
    margin-top: 2px;
}

#banner {
    min-height: max-content;
    width: 100%;
    display: block;
    height: auto;
}

#sectionA {
    background-color: #fff;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;
        display: flex;
        gap: 15px;

        @include breakpoint((vrsmall)) {
            flex-wrap: wrap;
        }

        @include breakpoint(small) {
            flex-wrap: wrap;
            justify-content: center;
        }

        @include breakpoint(medium) {
            flex-wrap: nowrap;
            align-items: center;
        }

        @include breakpoint(mdlarge) {
            flex-direction: row;
            justify-content: center;
            flex-wrap: nowrap;
        }

        .intro {
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                color: #4d4d4d;
            }
        }

        // .logos {
        //     display: flex;
        //     flex-direction: column;
        //     align-items: center;
        //     gap: 15px;
        //     padding-left: 15px;
        //     padding-right: 15px;
        //     max-width: 570px;
        //     overflow: hidden;
        //     // Background temporário para teste...
        //     background-color: #fff;
        //     // Bordas temporárias para teste...
        //     border-top-left-radius: 30px;
        //     border-bottom-right-radius: 30px;

        //     img#logoPanambiGaranti {
        //         display: block;
        //         max-width: 100%;
        //         height: auto;
        //     }

        //     img:last-of-type {
        //         width: 510px;
        //         max-width: 100%;
        //         height: auto;
        //         border-radius: 44.30px;
        //     }
        // }

        >img {
            display: block;
            height: auto;
            padding-left: 15px;
            padding-right: 15px;

            @include breakpoint(vrsmall) {
                max-width: 100%;
            }

            @include breakpoint(small) {
                max-width: 100%;
            }

            @include breakpoint(mdsmall) {
                max-width: 510px;
            }

            @include breakpoint(medium) {
                max-width: 510px;
            }

            @include breakpoint(mdlarge) {
                max-width: 570px;
            }
        }
    }
}

#sectionB {
    background-color: #3B4E9B;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        justify-content: center;

        .cards {
            display: flex;
            justify-content: space-around;
            width: inherit;
            gap: 50px;

            @include breakpoint(vrsmall) {
                flex-direction: column;
            }

            @include breakpoint(small) {
                flex-direction: column;
            }

            @include breakpoint(medium) {
                flex-direction: row;
            }

            figure {
                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;

                img {
                    max-width: 120px;
                }

                figcaption {
                    text-align: center;
                    color: #fff;

                    strong {
                        font-size: 1.25rem;
                        font-style: italic;
                    }
                }
            }
        }
    }


}

#sectionC {
    background-color: #174724;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        justify-content: center;

        .cards {
            display: flex;
            justify-content: space-around;
            width: inherit;
            gap: 50px;

            @include breakpoint(vrsmall) {
                flex-direction: column;
            }

            @include breakpoint(small) {
                flex-direction: column;
            }

            @include breakpoint(medium) {
                flex-direction: row;
            }

            figure {
                display: flex;
                flex-direction: column;
                gap: 15px;
                align-items: center;

                img {
                    max-width: 120px;
                }

                figcaption {
                    text-align: center;
                    color: #fff;

                    strong {
                        font-size: 1.25rem;
                        font-style: italic;
                    }
                }
            }
        }
    }
}

#sectionD {
    background-color: #3991c8;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 50px;

        header {
            width: 100%;
            display: flex;
            justify-content: space-around;
            gap: 50px;
            flex-wrap: wrap;

            @include breakpoint(vrsmall) {
                flex-direction: column;
                align-items: center;
                flex-wrap: nowrap;
                // flex-shrink: 0;
            }

            img {
                display: block;
                height: auto;
                width: auto;
            }


        }

        .intro-sebrae {
            text-align: center;
            color: #fff;

            p {
                max-width: 768px;
                margin: auto;
            }

            span {
                font-weight: bold;
            }
        }

        address.capacitacoes {
            display: grid;
            gap: 15px;

            @include breakpoint(medium) {
                grid-template-columns: repeat(2, 1fr);
            }
        }

        address.capacitacoes div h3 {
            color: #fff;
            font-style: normal;
            margin-bottom: 7.5px;
        }

        address.capacitacoes div a {
            display: block;
            color: #fff;
            font-style: normal;
            text-decoration: none;
            font-size: 0.850rem;
            padding: 15px 7.5px;
            background-color: #4d4d4d;
            border-radius: 26.8px;

            &:not(:last-of-type) {
                margin-bottom: 7.5px;
            }

            @include breakpoint(vrsmall) {
                font-size: 0.646rem;
                max-height: 53.6px;
            }
        }
    }
}

#sectionE {
    background-color: #089492;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 50px;
        align-items: center;

        article {
            display: flex;
            gap: 15px;
            justify-content: space-around;
            align-items: center;

            @include breakpoint(vrsmall) {
                flex-direction: column;
            }

            @include breakpoint(small) {
                flex-direction: column;
            }

            @include breakpoint(medium) {
                flex-direction: row;
            }

            &:last-of-type {
                div {
                    order: 2;
                    text-align: right;
                }

                figure {
                    order: 1;
                }
            }

            div {
                text-align: left;

                h3 {
                    font-size: 3rem;
                    color: #dbd999;
                    margin-bottom: 30px;
                    font-style: italic;
                }

                h4 {
                    font-size: 1.25rem;
                    color: #fff;
                    margin-bottom: 15px;
                }

                h5 {
                    font-size: 1.125rem;
                    color: #fff;
                    margin-bottom: 7.5px;
                }

                span {
                    display: block;
                    font-size: 1.1rem;
                    color: #fff;

                    &:not(:last-of-type) {
                        margin-bottom: 7.5px;
                    }

                    &:last-of-type {
                        font-weight: bold;
                    }
                }
            }

            figure {
                max-width: 570px;
                border-top-left-radius: 30px;
                border-bottom-right-radius: 30px;
                overflow: hidden;
                border: 4px solid #DBD999;

                img {
                    display: block;
                    height: auto;
                    max-width: 100%;

                }
            }
        }

        div#detalhes:last-child {
            // Detalhes
            background-color: #DBD999;
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 30px;
            padding: 30px;
            border-radius: 111px;
            max-width: max-content;

            img {
                display: block;
                height: auto;
                max-width: 100%;
            }

            ul {
                margin: 0;
                list-style-position: inside;

                li {
                    color: #089492;
                    text-align: left;
                }
            }
        }
    }
}

#sectionF {
    background-color: #DBD999;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;

        h2 {
            @include breakpoint(vrsmall) {
                font-size: 3rem !important;
            }
        }

        ul {
            margin: 0;
            list-style-position: inside;

            li {
                &::marker {
                    color: #089492;
                }

                margin-bottom: 7.5px;

                p,
                span {
                    display: block;
                    margin-top: 0;
                    margin-left: 22px;
                    margin-bottom: 7.5px;
                }

                &:last-of-type {
                    color: #089492;
                    font-weight: bold;
                }

                #negativasAtributos {
                    display: inline-block;
                    padding: 7.5px 15px;
                    margin-left: 15px;
                    background-color: #089492;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 18.69px;
                    transition: 0.3 ease-in-out;

                    &:hover {
                        background-color: #099e9b;
                    }
                }
            }
        }

        >p {
            font-weight: bold;
            font-style: italic;
        }
    }
}

#sectionForm {
    background-color: #36436F;
    padding: $internalSpace 15px;

    header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 50px;
        margin: auto;

        margin-bottom: 50px;

        @include breakpoint(vrsmall) {
            flex-direction: column;
        }

        @include breakpoint(small) {
            flex-direction: column;
        }

        @include breakpoint(medium) {
            flex-direction: row;
            width: 100%;
        }
    }

    form {
        width: 100%;

        input,
        select {
            position: relative;
            display: block;
            border: none;
            outline: none;
            background-color: #fafafa;
            padding: 15px;
            font-size: 1rem;
            font-size: 1rem;
            max-height: 48.4px;
            border-radius: 24.2px;
            color: #4d4d4d;
            width: 100%;

            &::placeholder {
                transition: 0.1s ease-in;
            }

            &:focus::placeholder {
                position: absolute;
                top: 2px;
                left: auto;
                font-size: 0.820rem;
            }
        }

        .pjForm {
            margin: auto;
            margin-bottom: 50px;

            @include breakpoint(vrsmall) {

                // Row 1
                .razaoSocialPj {
                    grid-column: span 12;
                }

                .cnpjPj {
                    grid-column: span 12;
                }

                .telefonePj {
                    grid-column: span 12;
                }

                // Row 2
                .cepPj {
                    grid-column: span 12;
                }

                .enderecoPj {
                    grid-column: span 12
                }

                .bairroPj {
                    grid-column: span 12;
                }

                .estadoPj {
                    grid-column: span 12;
                }

                // Row 3
                .cidadePj {
                    grid-column: span 12;
                }

                .emailPj {
                    grid-column: span 12;
                }

                .faturamentoPj {
                    grid-column: span 12;
                }

                .portePj {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxME,
                        .boxEPP {
                            width: 1rem;
                        }
                    }
                }

                // Row 4
                .atividadePj {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxIndustria,
                        .boxComercio,
                        .boxServico {
                            width: 1rem;
                        }
                    }
                }

                .associadoPj {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxIndustria,
                        .boxComercio,
                        .boxServico {
                            width: 1rem;
                        }
                    }
                }

                .agenciaPj {
                    grid-column: span 12;
                }
            }

            @include breakpoint(small) {

                // Row 1
                .razaoSocialPj {
                    grid-column: span 6;
                }

                .cnpjPj {
                    grid-column: span 6;
                }

                .telefonePj {
                    grid-column: span 5;
                }

                // Row 2
                .cepPj {
                    grid-column: span 7;
                }

                .enderecoPj {
                    grid-column: span 7;

                }

                .bairroPj {
                    grid-column: span 5;
                }

                .estadoPj {
                    grid-column: span 4;
                }

                // Row 3
                .cidadePj {
                    grid-column: span 8;
                }

                .emailPj {
                    grid-column: span 5;
                }

                .faturamentoPj {
                    grid-column: span 7;
                }

                .portePj {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxME,
                        .boxEPP {
                            width: 1rem;
                        }
                    }
                }

                // Row 4
                .atividadePj {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxIndustria,
                        .boxComercio,
                        .boxServico {
                            width: 1rem;
                        }
                    }
                }

                .associadoPj {
                    grid-column: span 6;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxIndustria,
                        .boxComercio,
                        .boxServico {
                            width: 1rem;
                        }
                    }
                }

                .agenciaPj {
                    grid-column: span 6;
                }
            }

            @include breakpoint(medium) {

                // Row 1
                .razaoSocialPj {
                    // grid-column: span 4;
                    grid-column: 1 / 5;
                }

                .cnpjPj {
                    // grid-column: span 4;
                    grid-column: 5 / 9;
                }

                .telefonePj {
                    // grid-column: span 4;
                    grid-column: 9 / 13;
                }

                // Row 2
                .cepPj {
                    grid-column: span 3;
                }

                .enderecoPj {
                    grid-column: span 3;

                }

                .bairroPj {
                    grid-column: span 3;
                }

                .estadoPj {
                    grid-column: span 3;
                }

                // Row 3
                .cidadePj {
                    grid-column: 1 / 3;
                }

                .emailPj {
                    grid-column: 3 / 7;
                }

                .faturamentoPj {
                    grid-column: 7 / 10;
                }

                .portePj {
                    grid-column: 10 / 13;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxME,
                        .boxEPP {
                            width: 1rem;
                        }
                    }
                }

                // Row 4
                .atividadePj {
                    grid-column: span 4;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxIndustria,
                        .boxComercio,
                        .boxServico {
                            width: 1rem;
                        }
                    }
                }

                .associadoPj {
                    grid-column: span 4;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    >label span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxIndustria,
                        .boxComercio,
                        .boxServico {
                            width: 1rem;
                        }
                    }
                }

                .agenciaPj {
                    grid-column: span 4;
                }
            }
        }

        .uploadForm {
            margin: auto;
            margin-bottom: 50px;

            #uploadForm {
                display: none;
            }

            >label {
                display: flex;
                align-items: center;
                gap: 7.5px;
                padding: 15px;
                max-height: 48.4px;
                border-radius: 24.2px;

                span:not(:last-of-type) {
                    display: block;
                    padding: 5px;
                    color: #4d4d4d;
                    background-color: #fff;

                    @include breakpoint(vrsmall) {
                        font-size: 0.890rem;
                        padding: 3px;
                    }
                }

                span {
                    color: #fff;

                    @include breakpoint(vrsmall) {
                        font-size: 0.890rem;
                    }
                }
            }
        }

        .pfForm,
        .socioForm {
            margin: auto;
            margin-bottom: 50px;
            position: relative;

            >h2 {
                &::after {
                    content: '';
                    display: block;
                    border-bottom: 2px solid currentColor;
                    margin: 4px auto 15px auto;
                }
            }

            >div:not(:last-of-type) {
                margin-bottom: inherit;
            }

            #conjugeRepresentantePf {
                h2 {
                    grid-column: span 12;

                    &::after {
                        content: '';
                        display: block;
                        border-bottom: 2px solid currentColor;
                        margin: 4px auto 15px auto;
                    }
                }
            }

            .socioRepresentantePf,
            .conjugeRepresentantePf {
                @include breakpoint(vrsmall) {

                    .cpfPf,
                    .nomePf,
                    .emailPf,
                    .telefonePf,
                    .dataNascimentoPf,
                    .nomeMaePf,
                    .rgPf,
                    .sexoPf,
                    .naturalidadePf,
                    .cepPf,
                    .enderecoPf,
                    .bairroPf,
                    .estadoPf,
                    .cidadePf,
                    .sociedadePf,
                    .complementoPf,
                    .profissaoPf,
                    .rendaPf {
                        grid-column: span 12;
                    }
                }

                @include breakpoint(small) {

                    .cpfPf,
                    .nomePf,
                    .emailPf,
                    .telefonePf,
                    .dataNascimentoPf,
                    .nomeMaePf,
                    .rgPf,
                    .sexoPf,
                    .naturalidadePf,
                    .cepPf,
                    .enderecoPf,
                    .bairroPf,
                    .estadoPf,
                    .cidadePf,
                    .complementoPf,
                    .profissaoPf {
                        grid-column: span 6;
                    }

                    .sociedadePf,
                    .rendaPf {
                        grid-column: span 12;
                    }
                }

                @include breakpoint(medium) {
                    .cpfPf {
                        grid-column: span 3;
                    }

                    .nomePf {
                        grid-column: span 5;
                    }

                    .emailPf {
                        grid-column: span 4;
                    }

                    .telefonePf {
                        grid-column: span 3;
                    }

                    .dataNascimentoPf {
                        grid-column: span 3;
                    }

                    .nomeMaePf {
                        grid-column: span 6;
                    }

                    .rgPf {
                        grid-column: span 2;
                    }

                    .sexoPf {
                        grid-column: span 2;
                    }

                    .naturalidadePf {
                        grid-column: span 3;
                    }

                    .cepPf {
                        grid-column: span 2;
                    }

                    .enderecoPf {
                        grid-column: span 3;
                    }

                    // Row 4
                    .bairroPf {
                        grid-column: span 4;
                    }

                    .estadoPf {
                        grid-column: span 2;
                    }

                    .cidadePf {
                        grid-column: span 3;
                    }

                    .sociedadePf {
                        grid-column: span 3;
                    }

                    // Cônjugue styles
                    .complementoPf {
                        grid-column: span 3;
                    }

                    .profissaoPf {
                        grid-column: span 6;
                    }

                    .rendaPf {
                        grid-column: span 6;
                    }
                }

                .estadoCivilPf {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 29.9px;
                    padding: 7.5px;

                    @include breakpoint(vrsmall) {
                        border-radius: 67.3px;
                    }

                    @include breakpoint(small) {
                        border-radius: 48.6px;
                    }

                    >label span {
                        display: block;
                        color: #fff;
                        text-align: center;
                    }

                    >div {
                        display: flex;
                        justify-content: center;
                        gap: 15px;
                        flex-wrap: wrap;

                        @include breakpoint(medium) {
                            flex-direction: row;
                        }

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                        }

                        .boxSolteiro,
                        .boxCasado,
                        .boxUniaoEstavel,
                        .boxDivorciado,
                        .boxViuvo {
                            width: 1rem;
                        }
                    }
                }
            }

            #add-btn {
                display: block;
                outline: none;
                border: none;
                padding: 30px;
                max-width: 100%;
                min-width: 100%;
                margin: 15px auto;
                font-size: 1.25rem;
                text-transform: uppercase;
                color: #fff;
                background-color: #F57F17;
                border-radius: 4px;
                transition: 0.3s ease-in-out;
                text-transform: uppercase;

                &:hover {
                    background-color: #fd861d;
                    cursor: pointer;
                }
            }

            .socioId {
                display: flex;
                flex-direction: column;

                #remove-btn {
                    align-self: flex-end;
                    display: block;
                    outline: none;
                    border: none;
                    padding: 18px 7.5px;
                    max-width: 140px;
                    font-size: 1.25rem;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    text-transform: uppercase;
                    color: #fff;
                    background-color: #D50000;
                    border-radius: 4px;
                    transition: 0.3s ease-in-out;
                    text-transform: uppercase;

                    &:hover {
                        background-color: #e80101;
                        cursor: pointer;
                    }
                }
            }
        }

        .creditoForm {
            margin: auto;
            margin-bottom: 50px;

            @include breakpoint(vrsmall) {

                // Row 1
                .valorDeCreditoPretendidoCf {
                    grid-column: span 12;
                }

                .numeroDeParcelasCf {
                    grid-column: span 12;
                }

                .carenciaCf {
                    grid-column: span 12;
                }

                // Row 2
                .finalidadeDoCreditoCf {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 30px;
                    padding: 7.5px 20px;

                    >label>span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                        margin-bottom: 7.5px;
                    }

                    >div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 7.5px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                            font-size: 1rem;

                            .label {
                                order: 1;
                            }
                        }

                        .boxCapitalDeGiroCf,
                        .boxPagamento13SalarioCf,
                        .boxInvestimentoCf,
                        .boxMarketingCf,
                        .boxTecnologiaCf {
                            width: 1rem;
                            order: 0;
                            align-self: center;
                        }
                    }

                }

                .empregados {
                    grid-column: span 12;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    // ..numeroAtualFuncionarioCf,
                    // .provaveisEmpregosCf {}
                }
            }

            @include breakpoint(small) {

                // Row 1
                .valorDeCreditoPretendidoCf {
                    grid-column: span 12;
                }

                .numeroDeParcelasCf {
                    grid-column: span 12;
                }

                .carenciaCf {
                    grid-column: span 12;
                }

                // Row 2
                .finalidadeDoCreditoCf {
                    grid-column: span 12;
                    font-size: 1rem;
                    border-radius: 30px;
                    padding: 7.5px 20px;

                    >label>span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                        margin-bottom: 7.5px;
                    }

                    >div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 7.5px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                            font-size: 1rem;

                            .label {
                                order: 1;
                            }
                        }

                        .boxCapitalDeGiroCf,
                        .boxPagamento13SalarioCf,
                        .boxInvestimentoCf,
                        .boxMarketingCf,
                        .boxTecnologiaCf {
                            width: 1rem;
                            order: 0;
                            align-self: center;
                        }
                    }
                }

                .empregados {
                    grid-column: span 12;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            @include breakpoint(medium) {

                // Row 1
                .valorDeCreditoPretendidoCf {
                    grid-column: span 4;
                }

                .numeroDeParcelasCf {
                    grid-column: span 4;
                }

                .carenciaCf {
                    grid-column: span 4;
                }

                // Row 2
                .finalidadeDoCreditoCf {
                    grid-column: span 6;
                    font-size: 1rem;
                    border-radius: 30px;
                    padding: 7.5px 20px;

                    >label>span {
                        display: block;
                        color: #fff;
                        font-size: 1rem;
                        text-align: center;
                        margin-bottom: 7.5px;
                    }

                    >div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 7.5px;

                        label {
                            color: #fff;
                            display: flex;
                            gap: 7.5px;
                            font-size: 1rem;

                            .label {
                                order: 1;
                            }
                        }

                        .boxCapitalDeGiroCf,
                        .boxPagamento13SalarioCf,
                        .boxInvestimentoCf,
                        .boxMarketingCf,
                        .boxTecnologiaCf {
                            width: 1rem;
                            order: 0;
                            align-self: center;
                        }
                    }

                }

                .empregados {
                    grid-column: span 6;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    // ..numeroAtualFuncionarioCf,
                    // .provaveisEmpregosCf {}
                }
            }
        }

        .termosForm {
            margin: auto;
            margin-bottom: 50px;

            .boxAutorizo1,
            .boxAutorizo2,
            .boxAutorizo3,
            .boxAutorizo4 {
                margin-bottom: 25px;

                >div {
                    display: flex;
                    gap: 7.5px;

                    >input[type="checkbox"] {
                        align-self: center;
                        width: initial;
                    }

                    >label {
                        color: #fff;
                        font-size: 1.125rem;

                        >#link {
                            cursor: pointer;
                            display: inline-block;
                            text-decoration: underline;
                            color: #fff;
                            transition: 0.3s ease-in-out;

                            &:hover {
                                color: #FD861D;
                            }
                        }
                    }
                }
            }

            p {
                color: #fff;
                text-align: center;
                font-size: 1.5rem;
            }
        }

        button:not(#remove-btn, #loading-content) {
            display: block;
            outline: none;
            border: none;
            padding: 15px;
            // width: 100% (mantém o max-width: 960px estático)
            width: 100%;
            max-width: 960px;
            margin: 15px auto auto auto;
            font-size: 1.35rem;
            text-transform: uppercase;
            color: #fff;
            background-color: #4458a9;

            border-radius: 26.50px;
            transition: 0.3s ease-in-out;

            &:hover {
                background-color: #485db1;
                cursor: pointer;
            }
        }

        // Spinner - Loading
        button#loading-content {
            display: block;
            border: none;
            outline: none;
            background-color: transparent;
            position: relative;
            width: 40px;
            height: 40px;
            margin: 15px auto auto auto;

            .loading {
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 5px solid transparent;
                border-top-color: rgba(240, 138, 83, 0.765);
                border-left-color: rgba(239, 143, 74, 0.727);

                &.animate-loading {
                    /* Animação */
                    animation: spin .8s linear infinite;
                }

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }

    .showMessage {
        display: block;
        margin: 7.5px auto 0 auto;
        width: max-content;
        padding: 7.5px;
        color: #fff;
        background-color: rgba(240, 138, 83, 0.765);
    }
}

#sectionG {
    background-color: #DBD999;

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;

        h2 {
            @include breakpoint(vrsmall) {
                font-size: 3rem !important;
            }
        }

        >div {
            margin-bottom: 25px;
            border: 2px solid #4d4d4d;
            padding: 25px;
            text-align: center;
            border-radius: 92.74px;
            max-height: max-content;

            h3 {
                margin-bottom: 15px;
                font-size: 1.7rem;
            }
        }

        >p {
            text-align: center;
            font-size: 1.5rem;
        }
    }
}

// Custom styles
#popup {
    background-color: #efefef;
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 2;
    max-height: 90vh;
    overflow-y: auto;

    button {
        border: none;
        outline: none;
        width: 120px;
        border-radius: 4px;
        background-color: #089492;
        color: #fff;
        padding: 9px 15px;
        position: fixed;
        top: calc($internalSpace);
        right: 15px;
        font-size: 1rem;
        transition: 0.3 ease-in-out;

        &:hover {
            background-color: #079a97;
            cursor: pointer;
        }
    }

    .container {
        width: 100%;
        margin: auto;
        padding: $internalSpace 15px;
        display: flex;
        flex-direction: column;

        h3 {
            font-size: 2.125rem;
            color: #089492;
            margin: 30px 0;
        }

        p:not(:last-of-type) {
            margin-bottom: 15px;
        }
    }

    &.popup {
        display: block;
    }
}

.content-cover {
    &.actived {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.6);
    }
}

footer {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;

    img {
        display: block;
        max-width: 120px;
        height: auto;
    }
}