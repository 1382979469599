@charset "UTF-8";
@media (max-width: 574px) {
  .container {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(12, 1fr);
}

.bgColorElement {
  background-color: #485db1;
}

.erroForm {
  display: block;
  color: #F9A825;
  font-size: 0.89rem;
  margin-top: 2px;
}

#banner {
  min-height: max-content;
  width: 100%;
  display: block;
  height: auto;
}

#sectionA {
  background-color: #fff;
}
#sectionA .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
  display: flex;
  gap: 15px;
}
@media (max-width: 574px) {
  #sectionA .container {
    flex-wrap: wrap;
  }
}
@media (min-width: 576px) {
  #sectionA .container {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (min-width: 992px) {
  #sectionA .container {
    flex-wrap: nowrap;
    align-items: center;
  }
}
@media (min-width: 1200px) {
  #sectionA .container {
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }
}
#sectionA .container .intro {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#sectionA .container .intro p {
  color: #4d4d4d;
}
#sectionA .container > img {
  display: block;
  height: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (max-width: 574px) {
  #sectionA .container > img {
    max-width: 100%;
  }
}
@media (min-width: 576px) {
  #sectionA .container > img {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  #sectionA .container > img {
    max-width: 510px;
  }
}
@media (min-width: 992px) {
  #sectionA .container > img {
    max-width: 510px;
  }
}
@media (min-width: 1200px) {
  #sectionA .container > img {
    max-width: 570px;
  }
}

#sectionB {
  background-color: #3B4E9B;
}
#sectionB .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
#sectionB .container .cards {
  display: flex;
  justify-content: space-around;
  width: inherit;
  gap: 50px;
}
@media (max-width: 574px) {
  #sectionB .container .cards {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionB .container .cards {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionB .container .cards {
    flex-direction: row;
  }
}
#sectionB .container .cards figure {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
#sectionB .container .cards figure img {
  max-width: 120px;
}
#sectionB .container .cards figure figcaption {
  text-align: center;
  color: #fff;
}
#sectionB .container .cards figure figcaption strong {
  font-size: 1.25rem;
  font-style: italic;
}

#sectionC {
  background-color: #174724;
}
#sectionC .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
#sectionC .container .cards {
  display: flex;
  justify-content: space-around;
  width: inherit;
  gap: 50px;
}
@media (max-width: 574px) {
  #sectionC .container .cards {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionC .container .cards {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionC .container .cards {
    flex-direction: row;
  }
}
#sectionC .container .cards figure {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
#sectionC .container .cards figure img {
  max-width: 120px;
}
#sectionC .container .cards figure figcaption {
  text-align: center;
  color: #fff;
}
#sectionC .container .cards figure figcaption strong {
  font-size: 1.25rem;
  font-style: italic;
}

#sectionD {
  background-color: #3991c8;
}
#sectionD .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 50px;
}
#sectionD .container header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 50px;
  flex-wrap: wrap;
}
@media (max-width: 574px) {
  #sectionD .container header {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
}
#sectionD .container header img {
  display: block;
  height: auto;
  width: auto;
}
#sectionD .container .intro-sebrae {
  text-align: center;
  color: #fff;
}
#sectionD .container .intro-sebrae p {
  max-width: 768px;
  margin: auto;
}
#sectionD .container .intro-sebrae span {
  font-weight: bold;
}
#sectionD .container address.capacitacoes {
  display: grid;
  gap: 15px;
}
@media (min-width: 992px) {
  #sectionD .container address.capacitacoes {
    grid-template-columns: repeat(2, 1fr);
  }
}
#sectionD .container address.capacitacoes div h3 {
  color: #fff;
  font-style: normal;
  margin-bottom: 7.5px;
}
#sectionD .container address.capacitacoes div a {
  display: block;
  color: #fff;
  font-style: normal;
  text-decoration: none;
  font-size: 0.85rem;
  padding: 15px 7.5px;
  background-color: #4d4d4d;
  border-radius: 26.8px;
}
#sectionD .container address.capacitacoes div a:not(:last-of-type) {
  margin-bottom: 7.5px;
}
@media (max-width: 574px) {
  #sectionD .container address.capacitacoes div a {
    font-size: 0.646rem;
    max-height: 53.6px;
  }
}

#sectionE {
  background-color: #089492;
}
#sectionE .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 50px;
  align-items: center;
}
#sectionE .container article {
  display: flex;
  gap: 15px;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 574px) {
  #sectionE .container article {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionE .container article {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionE .container article {
    flex-direction: row;
  }
}
#sectionE .container article:last-of-type div {
  order: 2;
  text-align: right;
}
#sectionE .container article:last-of-type figure {
  order: 1;
}
#sectionE .container article div {
  text-align: left;
}
#sectionE .container article div h3 {
  font-size: 3rem;
  color: #dbd999;
  margin-bottom: 30px;
  font-style: italic;
}
#sectionE .container article div h4 {
  font-size: 1.25rem;
  color: #fff;
  margin-bottom: 15px;
}
#sectionE .container article div h5 {
  font-size: 1.125rem;
  color: #fff;
  margin-bottom: 7.5px;
}
#sectionE .container article div span {
  display: block;
  font-size: 1.1rem;
  color: #fff;
}
#sectionE .container article div span:not(:last-of-type) {
  margin-bottom: 7.5px;
}
#sectionE .container article div span:last-of-type {
  font-weight: bold;
}
#sectionE .container article figure {
  max-width: 570px;
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  border: 4px solid #DBD999;
}
#sectionE .container article figure img {
  display: block;
  height: auto;
  max-width: 100%;
}
#sectionE .container div#detalhes:last-child {
  background-color: #DBD999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
  padding: 30px;
  border-radius: 111px;
  max-width: max-content;
}
#sectionE .container div#detalhes:last-child img {
  display: block;
  height: auto;
  max-width: 100%;
}
#sectionE .container div#detalhes:last-child ul {
  margin: 0;
  list-style-position: inside;
}
#sectionE .container div#detalhes:last-child ul li {
  color: #089492;
  text-align: left;
}

#sectionF {
  background-color: #DBD999;
}
#sectionF .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
}
@media (max-width: 574px) {
  #sectionF .container h2 {
    font-size: 3rem !important;
  }
}
#sectionF .container ul {
  margin: 0;
  list-style-position: inside;
}
#sectionF .container ul li {
  margin-bottom: 7.5px;
}
#sectionF .container ul li::marker {
  color: #089492;
}
#sectionF .container ul li p,
#sectionF .container ul li span {
  display: block;
  margin-top: 0;
  margin-left: 22px;
  margin-bottom: 7.5px;
}
#sectionF .container ul li:last-of-type {
  color: #089492;
  font-weight: bold;
}
#sectionF .container ul li #negativasAtributos {
  display: inline-block;
  padding: 7.5px 15px;
  margin-left: 15px;
  background-color: #089492;
  color: #fff;
  text-decoration: none;
  border-radius: 18.69px;
  transition: 0.3 ease-in-out;
}
#sectionF .container ul li #negativasAtributos:hover {
  background-color: #099e9b;
}
#sectionF .container > p {
  font-weight: bold;
  font-style: italic;
}

#sectionForm {
  background-color: #36436F;
  padding: 3.25rem 15px;
}
#sectionForm header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin: auto;
  margin-bottom: 50px;
}
@media (max-width: 574px) {
  #sectionForm header {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  #sectionForm header {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  #sectionForm header {
    flex-direction: row;
    width: 100%;
  }
}
#sectionForm form {
  width: 100%;
}
#sectionForm form input,
#sectionForm form select {
  position: relative;
  display: block;
  border: none;
  outline: none;
  background-color: #fafafa;
  padding: 15px;
  font-size: 1rem;
  font-size: 1rem;
  max-height: 48.4px;
  border-radius: 24.2px;
  color: #4d4d4d;
  width: 100%;
}
#sectionForm form input::placeholder,
#sectionForm form select::placeholder {
  transition: 0.1s ease-in;
}
#sectionForm form input:focus::placeholder,
#sectionForm form select:focus::placeholder {
  position: absolute;
  top: 2px;
  left: auto;
  font-size: 0.82rem;
}
#sectionForm form .pjForm {
  margin: auto;
  margin-bottom: 50px;
}
@media (max-width: 574px) {
  #sectionForm form .pjForm .razaoSocialPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .cnpjPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .telefonePj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .cepPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .enderecoPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .bairroPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .estadoPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .cidadePj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .emailPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .faturamentoPj {
    grid-column: span 12;
  }
  #sectionForm form .pjForm .portePj {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .portePj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .portePj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .portePj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .portePj > div .boxME,
  #sectionForm form .pjForm .portePj > div .boxEPP {
    width: 1rem;
  }
  #sectionForm form .pjForm .atividadePj {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .atividadePj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .atividadePj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .atividadePj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .atividadePj > div .boxIndustria,
  #sectionForm form .pjForm .atividadePj > div .boxComercio,
  #sectionForm form .pjForm .atividadePj > div .boxServico {
    width: 1rem;
  }
  #sectionForm form .pjForm .associadoPj {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .associadoPj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .associadoPj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .associadoPj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .associadoPj > div .boxIndustria,
  #sectionForm form .pjForm .associadoPj > div .boxComercio,
  #sectionForm form .pjForm .associadoPj > div .boxServico {
    width: 1rem;
  }
  #sectionForm form .pjForm .agenciaPj {
    grid-column: span 12;
  }
}
@media (min-width: 576px) {
  #sectionForm form .pjForm .razaoSocialPj {
    grid-column: span 6;
  }
  #sectionForm form .pjForm .cnpjPj {
    grid-column: span 6;
  }
  #sectionForm form .pjForm .telefonePj {
    grid-column: span 5;
  }
  #sectionForm form .pjForm .cepPj {
    grid-column: span 7;
  }
  #sectionForm form .pjForm .enderecoPj {
    grid-column: span 7;
  }
  #sectionForm form .pjForm .bairroPj {
    grid-column: span 5;
  }
  #sectionForm form .pjForm .estadoPj {
    grid-column: span 4;
  }
  #sectionForm form .pjForm .cidadePj {
    grid-column: span 8;
  }
  #sectionForm form .pjForm .emailPj {
    grid-column: span 5;
  }
  #sectionForm form .pjForm .faturamentoPj {
    grid-column: span 7;
  }
  #sectionForm form .pjForm .portePj {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .portePj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .portePj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .portePj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .portePj > div .boxME,
  #sectionForm form .pjForm .portePj > div .boxEPP {
    width: 1rem;
  }
  #sectionForm form .pjForm .atividadePj {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .atividadePj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .atividadePj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .atividadePj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .atividadePj > div .boxIndustria,
  #sectionForm form .pjForm .atividadePj > div .boxComercio,
  #sectionForm form .pjForm .atividadePj > div .boxServico {
    width: 1rem;
  }
  #sectionForm form .pjForm .associadoPj {
    grid-column: span 6;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .associadoPj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .associadoPj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .associadoPj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .associadoPj > div .boxIndustria,
  #sectionForm form .pjForm .associadoPj > div .boxComercio,
  #sectionForm form .pjForm .associadoPj > div .boxServico {
    width: 1rem;
  }
  #sectionForm form .pjForm .agenciaPj {
    grid-column: span 6;
  }
}
@media (min-width: 992px) {
  #sectionForm form .pjForm .razaoSocialPj {
    grid-column: 1/5;
  }
  #sectionForm form .pjForm .cnpjPj {
    grid-column: 5/9;
  }
  #sectionForm form .pjForm .telefonePj {
    grid-column: 9/13;
  }
  #sectionForm form .pjForm .cepPj {
    grid-column: span 3;
  }
  #sectionForm form .pjForm .enderecoPj {
    grid-column: span 3;
  }
  #sectionForm form .pjForm .bairroPj {
    grid-column: span 3;
  }
  #sectionForm form .pjForm .estadoPj {
    grid-column: span 3;
  }
  #sectionForm form .pjForm .cidadePj {
    grid-column: 1/3;
  }
  #sectionForm form .pjForm .emailPj {
    grid-column: 3/7;
  }
  #sectionForm form .pjForm .faturamentoPj {
    grid-column: 7/10;
  }
  #sectionForm form .pjForm .portePj {
    grid-column: 10/13;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .portePj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .portePj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .portePj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .portePj > div .boxME,
  #sectionForm form .pjForm .portePj > div .boxEPP {
    width: 1rem;
  }
  #sectionForm form .pjForm .atividadePj {
    grid-column: span 4;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .atividadePj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .atividadePj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .atividadePj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .atividadePj > div .boxIndustria,
  #sectionForm form .pjForm .atividadePj > div .boxComercio,
  #sectionForm form .pjForm .atividadePj > div .boxServico {
    width: 1rem;
  }
  #sectionForm form .pjForm .associadoPj {
    grid-column: span 4;
    font-size: 1rem;
    border-radius: 29.9px;
    padding: 7.5px;
  }
  #sectionForm form .pjForm .associadoPj > label span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }
  #sectionForm form .pjForm .associadoPj > div {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  #sectionForm form .pjForm .associadoPj > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
  }
  #sectionForm form .pjForm .associadoPj > div .boxIndustria,
  #sectionForm form .pjForm .associadoPj > div .boxComercio,
  #sectionForm form .pjForm .associadoPj > div .boxServico {
    width: 1rem;
  }
  #sectionForm form .pjForm .agenciaPj {
    grid-column: span 4;
  }
}
#sectionForm form .uploadForm {
  margin: auto;
  margin-bottom: 50px;
}
#sectionForm form .uploadForm #uploadForm {
  display: none;
}
#sectionForm form .uploadForm > label {
  display: flex;
  align-items: center;
  gap: 7.5px;
  padding: 15px;
  max-height: 48.4px;
  border-radius: 24.2px;
}
#sectionForm form .uploadForm > label span:not(:last-of-type) {
  display: block;
  padding: 5px;
  color: #4d4d4d;
  background-color: #fff;
}
@media (max-width: 574px) {
  #sectionForm form .uploadForm > label span:not(:last-of-type) {
    font-size: 0.89rem;
    padding: 3px;
  }
}
#sectionForm form .uploadForm > label span {
  color: #fff;
}
@media (max-width: 574px) {
  #sectionForm form .uploadForm > label span {
    font-size: 0.89rem;
  }
}
#sectionForm form .pfForm,
#sectionForm form .socioForm {
  margin: auto;
  margin-bottom: 50px;
  position: relative;
}
#sectionForm form .pfForm > h2::after,
#sectionForm form .socioForm > h2::after {
  content: "";
  display: block;
  border-bottom: 2px solid currentColor;
  margin: 4px auto 15px auto;
}
#sectionForm form .pfForm > div:not(:last-of-type),
#sectionForm form .socioForm > div:not(:last-of-type) {
  margin-bottom: inherit;
}
#sectionForm form .pfForm #conjugeRepresentantePf h2,
#sectionForm form .socioForm #conjugeRepresentantePf h2 {
  grid-column: span 12;
}
#sectionForm form .pfForm #conjugeRepresentantePf h2::after,
#sectionForm form .socioForm #conjugeRepresentantePf h2::after {
  content: "";
  display: block;
  border-bottom: 2px solid currentColor;
  margin: 4px auto 15px auto;
}
@media (max-width: 574px) {
  #sectionForm form .pfForm .socioRepresentantePf .cpfPf,
  #sectionForm form .pfForm .socioRepresentantePf .nomePf,
  #sectionForm form .pfForm .socioRepresentantePf .emailPf,
  #sectionForm form .pfForm .socioRepresentantePf .telefonePf,
  #sectionForm form .pfForm .socioRepresentantePf .dataNascimentoPf,
  #sectionForm form .pfForm .socioRepresentantePf .nomeMaePf,
  #sectionForm form .pfForm .socioRepresentantePf .rgPf,
  #sectionForm form .pfForm .socioRepresentantePf .sexoPf,
  #sectionForm form .pfForm .socioRepresentantePf .naturalidadePf,
  #sectionForm form .pfForm .socioRepresentantePf .cepPf,
  #sectionForm form .pfForm .socioRepresentantePf .enderecoPf,
  #sectionForm form .pfForm .socioRepresentantePf .bairroPf,
  #sectionForm form .pfForm .socioRepresentantePf .estadoPf,
  #sectionForm form .pfForm .socioRepresentantePf .cidadePf,
  #sectionForm form .pfForm .socioRepresentantePf .sociedadePf,
  #sectionForm form .pfForm .socioRepresentantePf .complementoPf,
  #sectionForm form .pfForm .socioRepresentantePf .profissaoPf,
  #sectionForm form .pfForm .socioRepresentantePf .rendaPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cpfPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .nomePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .emailPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .telefonePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .dataNascimentoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .nomeMaePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .rgPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .sexoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .naturalidadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cepPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .enderecoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .bairroPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .estadoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cidadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .sociedadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .complementoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .profissaoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .rendaPf,
  #sectionForm form .socioForm .socioRepresentantePf .cpfPf,
  #sectionForm form .socioForm .socioRepresentantePf .nomePf,
  #sectionForm form .socioForm .socioRepresentantePf .emailPf,
  #sectionForm form .socioForm .socioRepresentantePf .telefonePf,
  #sectionForm form .socioForm .socioRepresentantePf .dataNascimentoPf,
  #sectionForm form .socioForm .socioRepresentantePf .nomeMaePf,
  #sectionForm form .socioForm .socioRepresentantePf .rgPf,
  #sectionForm form .socioForm .socioRepresentantePf .sexoPf,
  #sectionForm form .socioForm .socioRepresentantePf .naturalidadePf,
  #sectionForm form .socioForm .socioRepresentantePf .cepPf,
  #sectionForm form .socioForm .socioRepresentantePf .enderecoPf,
  #sectionForm form .socioForm .socioRepresentantePf .bairroPf,
  #sectionForm form .socioForm .socioRepresentantePf .estadoPf,
  #sectionForm form .socioForm .socioRepresentantePf .cidadePf,
  #sectionForm form .socioForm .socioRepresentantePf .sociedadePf,
  #sectionForm form .socioForm .socioRepresentantePf .complementoPf,
  #sectionForm form .socioForm .socioRepresentantePf .profissaoPf,
  #sectionForm form .socioForm .socioRepresentantePf .rendaPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cpfPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .nomePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .emailPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .telefonePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .dataNascimentoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .nomeMaePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .rgPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .sexoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .naturalidadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cepPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .enderecoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .bairroPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .estadoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cidadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .sociedadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .complementoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .profissaoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .rendaPf {
    grid-column: span 12;
  }
}
@media (min-width: 576px) {
  #sectionForm form .pfForm .socioRepresentantePf .cpfPf,
  #sectionForm form .pfForm .socioRepresentantePf .nomePf,
  #sectionForm form .pfForm .socioRepresentantePf .emailPf,
  #sectionForm form .pfForm .socioRepresentantePf .telefonePf,
  #sectionForm form .pfForm .socioRepresentantePf .dataNascimentoPf,
  #sectionForm form .pfForm .socioRepresentantePf .nomeMaePf,
  #sectionForm form .pfForm .socioRepresentantePf .rgPf,
  #sectionForm form .pfForm .socioRepresentantePf .sexoPf,
  #sectionForm form .pfForm .socioRepresentantePf .naturalidadePf,
  #sectionForm form .pfForm .socioRepresentantePf .cepPf,
  #sectionForm form .pfForm .socioRepresentantePf .enderecoPf,
  #sectionForm form .pfForm .socioRepresentantePf .bairroPf,
  #sectionForm form .pfForm .socioRepresentantePf .estadoPf,
  #sectionForm form .pfForm .socioRepresentantePf .cidadePf,
  #sectionForm form .pfForm .socioRepresentantePf .complementoPf,
  #sectionForm form .pfForm .socioRepresentantePf .profissaoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cpfPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .nomePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .emailPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .telefonePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .dataNascimentoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .nomeMaePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .rgPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .sexoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .naturalidadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cepPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .enderecoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .bairroPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .estadoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cidadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .complementoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .profissaoPf,
  #sectionForm form .socioForm .socioRepresentantePf .cpfPf,
  #sectionForm form .socioForm .socioRepresentantePf .nomePf,
  #sectionForm form .socioForm .socioRepresentantePf .emailPf,
  #sectionForm form .socioForm .socioRepresentantePf .telefonePf,
  #sectionForm form .socioForm .socioRepresentantePf .dataNascimentoPf,
  #sectionForm form .socioForm .socioRepresentantePf .nomeMaePf,
  #sectionForm form .socioForm .socioRepresentantePf .rgPf,
  #sectionForm form .socioForm .socioRepresentantePf .sexoPf,
  #sectionForm form .socioForm .socioRepresentantePf .naturalidadePf,
  #sectionForm form .socioForm .socioRepresentantePf .cepPf,
  #sectionForm form .socioForm .socioRepresentantePf .enderecoPf,
  #sectionForm form .socioForm .socioRepresentantePf .bairroPf,
  #sectionForm form .socioForm .socioRepresentantePf .estadoPf,
  #sectionForm form .socioForm .socioRepresentantePf .cidadePf,
  #sectionForm form .socioForm .socioRepresentantePf .complementoPf,
  #sectionForm form .socioForm .socioRepresentantePf .profissaoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cpfPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .nomePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .emailPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .telefonePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .dataNascimentoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .nomeMaePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .rgPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .sexoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .naturalidadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cepPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .enderecoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .bairroPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .estadoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cidadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .complementoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .profissaoPf {
    grid-column: span 6;
  }
  #sectionForm form .pfForm .socioRepresentantePf .sociedadePf,
  #sectionForm form .pfForm .socioRepresentantePf .rendaPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .sociedadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .rendaPf,
  #sectionForm form .socioForm .socioRepresentantePf .sociedadePf,
  #sectionForm form .socioForm .socioRepresentantePf .rendaPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .sociedadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .rendaPf {
    grid-column: span 12;
  }
}
@media (min-width: 992px) {
  #sectionForm form .pfForm .socioRepresentantePf .cpfPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cpfPf,
  #sectionForm form .socioForm .socioRepresentantePf .cpfPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cpfPf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .nomePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .nomePf,
  #sectionForm form .socioForm .socioRepresentantePf .nomePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .nomePf {
    grid-column: span 5;
  }
  #sectionForm form .pfForm .socioRepresentantePf .emailPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .emailPf,
  #sectionForm form .socioForm .socioRepresentantePf .emailPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .emailPf {
    grid-column: span 4;
  }
  #sectionForm form .pfForm .socioRepresentantePf .telefonePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .telefonePf,
  #sectionForm form .socioForm .socioRepresentantePf .telefonePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .telefonePf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .dataNascimentoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .dataNascimentoPf,
  #sectionForm form .socioForm .socioRepresentantePf .dataNascimentoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .dataNascimentoPf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .nomeMaePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .nomeMaePf,
  #sectionForm form .socioForm .socioRepresentantePf .nomeMaePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .nomeMaePf {
    grid-column: span 6;
  }
  #sectionForm form .pfForm .socioRepresentantePf .rgPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .rgPf,
  #sectionForm form .socioForm .socioRepresentantePf .rgPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .rgPf {
    grid-column: span 2;
  }
  #sectionForm form .pfForm .socioRepresentantePf .sexoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .sexoPf,
  #sectionForm form .socioForm .socioRepresentantePf .sexoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .sexoPf {
    grid-column: span 2;
  }
  #sectionForm form .pfForm .socioRepresentantePf .naturalidadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .naturalidadePf,
  #sectionForm form .socioForm .socioRepresentantePf .naturalidadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .naturalidadePf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .cepPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cepPf,
  #sectionForm form .socioForm .socioRepresentantePf .cepPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cepPf {
    grid-column: span 2;
  }
  #sectionForm form .pfForm .socioRepresentantePf .enderecoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .enderecoPf,
  #sectionForm form .socioForm .socioRepresentantePf .enderecoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .enderecoPf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .bairroPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .bairroPf,
  #sectionForm form .socioForm .socioRepresentantePf .bairroPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .bairroPf {
    grid-column: span 4;
  }
  #sectionForm form .pfForm .socioRepresentantePf .estadoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .estadoPf,
  #sectionForm form .socioForm .socioRepresentantePf .estadoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .estadoPf {
    grid-column: span 2;
  }
  #sectionForm form .pfForm .socioRepresentantePf .cidadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .cidadePf,
  #sectionForm form .socioForm .socioRepresentantePf .cidadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .cidadePf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .sociedadePf,
  #sectionForm form .pfForm .conjugeRepresentantePf .sociedadePf,
  #sectionForm form .socioForm .socioRepresentantePf .sociedadePf,
  #sectionForm form .socioForm .conjugeRepresentantePf .sociedadePf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .complementoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .complementoPf,
  #sectionForm form .socioForm .socioRepresentantePf .complementoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .complementoPf {
    grid-column: span 3;
  }
  #sectionForm form .pfForm .socioRepresentantePf .profissaoPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .profissaoPf,
  #sectionForm form .socioForm .socioRepresentantePf .profissaoPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .profissaoPf {
    grid-column: span 6;
  }
  #sectionForm form .pfForm .socioRepresentantePf .rendaPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .rendaPf,
  #sectionForm form .socioForm .socioRepresentantePf .rendaPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .rendaPf {
    grid-column: span 6;
  }
}
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf {
  grid-column: span 12;
  font-size: 1rem;
  border-radius: 29.9px;
  padding: 7.5px;
}
@media (max-width: 574px) {
  #sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf,
  #sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf {
    border-radius: 67.3px;
  }
}
@media (min-width: 576px) {
  #sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf,
  #sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf,
  #sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf,
  #sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf {
    border-radius: 48.6px;
  }
}
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > label span,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > label span,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > label span,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > label span {
  display: block;
  color: #fff;
  text-align: center;
}
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  #sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div,
  #sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div,
  #sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div,
  #sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div {
    flex-direction: row;
  }
}
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div label,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div label,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div label,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div label {
  color: #fff;
  display: flex;
  gap: 7.5px;
}
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div .boxSolteiro,
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div .boxCasado,
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div .boxUniaoEstavel,
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div .boxDivorciado,
#sectionForm form .pfForm .socioRepresentantePf .estadoCivilPf > div .boxViuvo,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div .boxSolteiro,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div .boxCasado,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div .boxUniaoEstavel,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div .boxDivorciado,
#sectionForm form .pfForm .conjugeRepresentantePf .estadoCivilPf > div .boxViuvo,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div .boxSolteiro,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div .boxCasado,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div .boxUniaoEstavel,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div .boxDivorciado,
#sectionForm form .socioForm .socioRepresentantePf .estadoCivilPf > div .boxViuvo,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div .boxSolteiro,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div .boxCasado,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div .boxUniaoEstavel,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div .boxDivorciado,
#sectionForm form .socioForm .conjugeRepresentantePf .estadoCivilPf > div .boxViuvo {
  width: 1rem;
}
#sectionForm form .pfForm #add-btn,
#sectionForm form .socioForm #add-btn {
  display: block;
  outline: none;
  border: none;
  padding: 30px;
  max-width: 100%;
  min-width: 100%;
  margin: 15px auto;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #F57F17;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
}
#sectionForm form .pfForm #add-btn:hover,
#sectionForm form .socioForm #add-btn:hover {
  background-color: #fd861d;
  cursor: pointer;
}
#sectionForm form .pfForm .socioId,
#sectionForm form .socioForm .socioId {
  display: flex;
  flex-direction: column;
}
#sectionForm form .pfForm .socioId #remove-btn,
#sectionForm form .socioForm .socioId #remove-btn {
  align-self: flex-end;
  display: block;
  outline: none;
  border: none;
  padding: 18px 7.5px;
  max-width: 140px;
  font-size: 1.25rem;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #fff;
  background-color: #D50000;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
}
#sectionForm form .pfForm .socioId #remove-btn:hover,
#sectionForm form .socioForm .socioId #remove-btn:hover {
  background-color: #e80101;
  cursor: pointer;
}
#sectionForm form .creditoForm {
  margin: auto;
  margin-bottom: 50px;
}
@media (max-width: 574px) {
  #sectionForm form .creditoForm .valorDeCreditoPretendidoCf {
    grid-column: span 12;
  }
  #sectionForm form .creditoForm .numeroDeParcelasCf {
    grid-column: span 12;
  }
  #sectionForm form .creditoForm .carenciaCf {
    grid-column: span 12;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 30px;
    padding: 7.5px 20px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > label > span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 7.5px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7.5px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
    font-size: 1rem;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div label .label {
    order: 1;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxCapitalDeGiroCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxPagamento13SalarioCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxInvestimentoCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxMarketingCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxTecnologiaCf {
    width: 1rem;
    order: 0;
    align-self: center;
  }
  #sectionForm form .creditoForm .empregados {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
@media (min-width: 576px) {
  #sectionForm form .creditoForm .valorDeCreditoPretendidoCf {
    grid-column: span 12;
  }
  #sectionForm form .creditoForm .numeroDeParcelasCf {
    grid-column: span 12;
  }
  #sectionForm form .creditoForm .carenciaCf {
    grid-column: span 12;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf {
    grid-column: span 12;
    font-size: 1rem;
    border-radius: 30px;
    padding: 7.5px 20px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > label > span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 7.5px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7.5px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
    font-size: 1rem;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div label .label {
    order: 1;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxCapitalDeGiroCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxPagamento13SalarioCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxInvestimentoCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxMarketingCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxTecnologiaCf {
    width: 1rem;
    order: 0;
    align-self: center;
  }
  #sectionForm form .creditoForm .empregados {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
@media (min-width: 992px) {
  #sectionForm form .creditoForm .valorDeCreditoPretendidoCf {
    grid-column: span 4;
  }
  #sectionForm form .creditoForm .numeroDeParcelasCf {
    grid-column: span 4;
  }
  #sectionForm form .creditoForm .carenciaCf {
    grid-column: span 4;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf {
    grid-column: span 6;
    font-size: 1rem;
    border-radius: 30px;
    padding: 7.5px 20px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > label > span {
    display: block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 7.5px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7.5px;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div label {
    color: #fff;
    display: flex;
    gap: 7.5px;
    font-size: 1rem;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div label .label {
    order: 1;
  }
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxCapitalDeGiroCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxPagamento13SalarioCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxInvestimentoCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxMarketingCf,
  #sectionForm form .creditoForm .finalidadeDoCreditoCf > div .boxTecnologiaCf {
    width: 1rem;
    order: 0;
    align-self: center;
  }
  #sectionForm form .creditoForm .empregados {
    grid-column: span 6;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
#sectionForm form .termosForm {
  margin: auto;
  margin-bottom: 50px;
}
#sectionForm form .termosForm .boxAutorizo1,
#sectionForm form .termosForm .boxAutorizo2,
#sectionForm form .termosForm .boxAutorizo3,
#sectionForm form .termosForm .boxAutorizo4 {
  margin-bottom: 25px;
}
#sectionForm form .termosForm .boxAutorizo1 > div,
#sectionForm form .termosForm .boxAutorizo2 > div,
#sectionForm form .termosForm .boxAutorizo3 > div,
#sectionForm form .termosForm .boxAutorizo4 > div {
  display: flex;
  gap: 7.5px;
}
#sectionForm form .termosForm .boxAutorizo1 > div > input[type=checkbox],
#sectionForm form .termosForm .boxAutorizo2 > div > input[type=checkbox],
#sectionForm form .termosForm .boxAutorizo3 > div > input[type=checkbox],
#sectionForm form .termosForm .boxAutorizo4 > div > input[type=checkbox] {
  align-self: center;
  width: initial;
}
#sectionForm form .termosForm .boxAutorizo1 > div > label,
#sectionForm form .termosForm .boxAutorizo2 > div > label,
#sectionForm form .termosForm .boxAutorizo3 > div > label,
#sectionForm form .termosForm .boxAutorizo4 > div > label {
  color: #fff;
  font-size: 1.125rem;
}
#sectionForm form .termosForm .boxAutorizo1 > div > label > #link,
#sectionForm form .termosForm .boxAutorizo2 > div > label > #link,
#sectionForm form .termosForm .boxAutorizo3 > div > label > #link,
#sectionForm form .termosForm .boxAutorizo4 > div > label > #link {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
  color: #fff;
  transition: 0.3s ease-in-out;
}
#sectionForm form .termosForm .boxAutorizo1 > div > label > #link:hover,
#sectionForm form .termosForm .boxAutorizo2 > div > label > #link:hover,
#sectionForm form .termosForm .boxAutorizo3 > div > label > #link:hover,
#sectionForm form .termosForm .boxAutorizo4 > div > label > #link:hover {
  color: #FD861D;
}
#sectionForm form .termosForm p {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
}
#sectionForm form button:not(#remove-btn, #loading-content) {
  display: block;
  outline: none;
  border: none;
  padding: 15px;
  width: 100%;
  max-width: 960px;
  margin: 15px auto auto auto;
  font-size: 1.35rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #4458a9;
  border-radius: 26.5px;
  transition: 0.3s ease-in-out;
}
#sectionForm form button:not(#remove-btn, #loading-content):hover {
  background-color: #485db1;
  cursor: pointer;
}
#sectionForm form button#loading-content {
  display: block;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 15px auto auto auto;
}
#sectionForm form button#loading-content .loading {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: rgba(240, 138, 83, 0.765);
  border-left-color: rgba(239, 143, 74, 0.727);
}
#sectionForm form button#loading-content .loading.animate-loading {
  /* Animação */
  animation: spin 0.8s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#sectionForm .showMessage {
  display: block;
  margin: 7.5px auto 0 auto;
  width: max-content;
  padding: 7.5px;
  color: #fff;
  background-color: rgba(240, 138, 83, 0.765);
}

#sectionG {
  background-color: #DBD999;
}
#sectionG .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
}
@media (max-width: 574px) {
  #sectionG .container h2 {
    font-size: 3rem !important;
  }
}
#sectionG .container > div {
  margin-bottom: 25px;
  border: 2px solid #4d4d4d;
  padding: 25px;
  text-align: center;
  border-radius: 92.74px;
  max-height: max-content;
}
#sectionG .container > div h3 {
  margin-bottom: 15px;
  font-size: 1.7rem;
}
#sectionG .container > p {
  text-align: center;
  font-size: 1.5rem;
}

#popup {
  background-color: #efefef;
  display: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 2;
  max-height: 90vh;
  overflow-y: auto;
}
#popup button {
  border: none;
  outline: none;
  width: 120px;
  border-radius: 4px;
  background-color: #089492;
  color: #fff;
  padding: 9px 15px;
  position: fixed;
  top: 3.25rem;
  right: 15px;
  font-size: 1rem;
  transition: 0.3 ease-in-out;
}
#popup button:hover {
  background-color: #079a97;
  cursor: pointer;
}
#popup .container {
  width: 100%;
  margin: auto;
  padding: 3.25rem 15px;
  display: flex;
  flex-direction: column;
}
#popup .container h3 {
  font-size: 2.125rem;
  color: #089492;
  margin: 30px 0;
}
#popup .container p:not(:last-of-type) {
  margin-bottom: 15px;
}
#popup.popup {
  display: block;
}

.content-cover.actived {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

footer {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}
footer img {
  display: block;
  max-width: 120px;
  height: auto;
}

